export class AppealCommand {
    #_subject = '';

    constructor(context) {
    }

    execute(context) {
        const self = context.object
        self.showFull = true
    }
}