<template>
  <div class="switch-theme-container" :class="{'dark':blackTheme}">
    <label class="switch" for="switch">
      <input type="checkbox" v-model="blackTheme" id="switch" />
      <div class="sunmoon">
        <div class="darkside"></div>
      </div>
      <div class="border"></div>
      <div class="clouds">
        <img src="/img/switcher_theme/cloud_1.svg" alt="" class="cloud cloud-1" />
        <img src="/img/switcher_theme/cloud_2.svg" alt="" class="cloud cloud-2" />
        <img src="/img/switcher_theme/cloud_3.svg" alt="" class="cloud cloud-3" />
        <img src="/img/switcher_theme/cloud_4.svg" alt="" class="cloud cloud-4" />
        <img src="/img/switcher_theme/stars.svg" alt="" class="stars" />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "switcherTheme",
  computed:{
    blackTheme:{
      get:function() {
        return this.$store.getters.getBlackTheme
      },
      // setter
      set: function(newValue) {
        this.$store.commit('SET_BLACK_THEME', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.switch-theme-container{
  display: flex;
  justify-content: center;

  --bg-color: #cde7ff;
  --switch-width: 50px;
  --switch-height: 21px;
  --switch-round: 50px;
  --switch-padding-x: 5px;
  --switch-bg: linear-gradient(to bottom, #73bbff, #a2d1fd);
  --switch-dark-bg: linear-gradient(to top, #2b3347, #181d27);
  --border-width: 2px;
  --border-gradient: linear-gradient(to bottom, #a2d1fd, #cde7ff);
  --border-dark-gradient: linear-gradient(to bottom, #000000, #6c7384);
  --sunmoon-size: 18px;
  --transition: all 0.5s ease;
  .switch {
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--switch-width);
    height: var(--switch-height);
    border-radius: var(--switch-round);
    cursor: pointer;
    transition: var(--transition);
    background: var(--switch-bg);
    .border {
      position: absolute;
      top: calc(var(--border-width) * -1);
      left: calc(var(--border-width) * -1);
      width: calc(100% + var(--border-width) * 2);
      height: calc(100% + var(--border-width) * 2);
      border-radius: var(--switch-round);
      background: var(--border-gradient);
      z-index: -1;
      transition: var(--transition);
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--switch-round);
        background: var(--border-dark-gradient);
        opacity: 0;
        transition: var(--transition);
      }
    }
    input {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--switch-round);
      opacity: 0;
      background: var(--switch-dark-bg);
      transition: var(--transition);
    }
  }
  .sunmoon {
    position: absolute;
    width: var(--sunmoon-size);
    height: var(--sunmoon-size);
    border-radius: 50%;
    transition: var(--transition);
    left: var(--switch-padding-x);
    z-index: 1;
    background-color: #FFC187;
    box-shadow: 0px 0px 11.7px 0px #FFC187, 0px 0px 20px 0px #ffc18768, -2px -2px 5px 0px #ffab5c inset;
  }
  .darkside {
    position: absolute;
    top: 0.6px;
    left: 0.6px;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: #FFC187;
    transition: var(--transition);
  }
  .clouds {
    border-radius: var(--switch-round);
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .cloud {
      position: absolute;
      width: 60%;
      transition: var(--transition);
    }

    .cloud-1 {
      bottom: -55%;
      left: 0;
    }

    .cloud-2 {
      bottom: -45%;
      left: 8px;
    }

    .cloud-3 {
      bottom: -40%;
      right: 0px;
    }

    .cloud-4 {
      bottom: -16%;
      right: -8px;
    }

  }
  .stars {
    position: absolute;
    top: 150%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
    transition: var(--transition);
    width: calc(100% - var(--sunmoon-size) - var(--switch-padding-x));
    height: 100%;
  }

  &.dark{
    .switch {
      .border{
        &::after {
          opacity: 1;
        }
      }
      &::before {
        opacity: 1;
      }
    }
    .sunmoon {
      left: calc(100% - var(--sunmoon-size) - var(--switch-padding-x));
      background-color: #dee5f3;
      box-shadow: 0px 0px 51.7px 0px #dee5f3;
    }
    .darkside {
      background-color: #565c6b;
    }
    .clouds {
      .cloud-1 {
        bottom: -35%;
        left: -110px;
      }

      .cloud-2 {
        bottom: -15%;
        left: -110px;
        transition: all 0.7s ease;
      }

      .cloud-3 {
        bottom: -15%;
        right: -110px;
      }

      .cloud-4 {
        bottom: -5%;
        right: -110px;
        transition: all 0.7s ease;
      }


    }
    .stars {
      top: 50%;
    }
  }
}
</style>