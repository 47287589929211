<template>
  <div class="add-to-collection-component">
    <modal-container
        v-if="visibleAddCollection"
        :title="$t('ДобавлениеВПодборки')"
        :header-lines="2"
        :customBody="true"
        :confirmText="collections.length?$t('Добавить'):''"
        :btn-close-text="collections.length?$t('Отменить'):''"
        :confirm-disabled="!collections.some((el)=>el.selected===true)"
        @close-modal="$emit('close')"
        @confirm-option="addInCollection"
        :key="'visibleAddCollection'"
    >
      <div class="body-add-to-collection fix-height">
        <div class="body-add-to-collection__container">
          <div class="list-objects-selected">
            <div class="list-objects-selected__title">{{$t('ВыбраноВами')}}</div>
            <div class="list-objects-selected__slider" v-dragScrollMouse>
              <template v-for="(selected,index) in objectsSelected">
                <div class="selected-el" :key="selected.id" v-if="index<8">
                  <div class="selected-el__container" :style="{'--linkPlanSelected':selected.preview?`url(https://5555.itrielt.ru/getCroppedPlan?url_plan_encrypted=${encodeBase(selected.preview)})`:'url(/images/ObjectStub.svg)'}">
                  </div>
                </div>
              </template>
              <div class="more-selected-elements" v-if="objectsSelected.length>8">
                <indicator-tag
                    :text="moreInidicator"
                    size="large"
                    type="secondary"
                    appearance="neutral"
                />
              </div>
            </div>
          </div>
          <div class="separator-add-collection">
            <div class="separator-add-collection__line"></div>
          </div>
          <template v-if="!loadCollections">
            <div class="list-collection" :key="'list-collection'" v-if="filterCollections.length">
              <div class="collection-block" v-for="collection in filterCollections" :key="collection.id">
                <div class="collection-block__left">
                  <div class="collection-block-plan">
                    <div class="collection-block-plan__container">
                      <img
                          class="collection-block-plan__container_img"
                          :src="collection.preview?.length?`https://5555.itrielt.ru/getCroppedPlan?url_plan_encrypted=${encodeBase(collection.preview[0])}`:'/images/ObjectStub.svg'"
                      >
                    </div>
                  </div>
                </div>
                <div class="collection-block__middle">
                  <div class="collection-block-name">{{collection.name}}</div>
                </div>
                <div class="collection-block__right">
                  <div class="collection-block__right_icon" @click="selectCollection(collection.id)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="collection.selected">
                      <path d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="var(--colorIconAccentInvariably)"/>
                    </svg>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                      <path d="M11 13V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V13H16C16.2833 13 16.5208 12.9042 16.7125 12.7125C16.9042 12.5208 17 12.2833 17 12C17 11.7167 16.9042 11.4792 16.7125 11.2875C16.5208 11.0958 16.2833 11 16 11H13V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V11H8C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13H11ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="var(--colorIconSecondary)"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty-collections" :key="'empty-collections'" v-else>
              <div class="empty-collections__body">
                <div class="empty-collections__body_header">
                  <div class="empty-collections-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.9997 21L9.09967 23.1C8.3219 23.4306 7.58301 23.3674 6.88301 22.9104C6.18301 22.4535 5.83301 21.8069 5.83301 20.9708V5.83333C5.83301 5.19167 6.06148 4.64236 6.51842 4.18542C6.97537 3.72847 7.52467 3.5 8.16634 3.5H13.9997C14.3302 3.5 14.6073 3.61181 14.8309 3.83542C15.0545 4.05903 15.1663 4.33611 15.1663 4.66667C15.1663 4.99722 15.0545 5.27431 14.8309 5.49792C14.6073 5.72153 14.3302 5.83333 13.9997 5.83333H8.16634V20.9417L13.9997 18.4333L19.833 20.9417V14C19.833 13.6694 19.9448 13.3924 20.1684 13.1688C20.392 12.9451 20.6691 12.8333 20.9997 12.8333C21.3302 12.8333 21.6073 12.9451 21.8309 13.1688C22.0545 13.3924 22.1663 13.6694 22.1663 14V20.9708C22.1663 21.8069 21.8163 22.4535 21.1163 22.9104C20.4163 23.3674 19.6775 23.4306 18.8997 23.1L13.9997 21ZM13.9997 5.83333H8.16634H15.1663H13.9997ZM19.833 8.16667H18.6663C18.3358 8.16667 18.0587 8.05486 17.8351 7.83125C17.6115 7.60764 17.4997 7.33056 17.4997 7C17.4997 6.66944 17.6115 6.39236 17.8351 6.16875C18.0587 5.94514 18.3358 5.83333 18.6663 5.83333H19.833V4.66667C19.833 4.33611 19.9448 4.05903 20.1684 3.83542C20.392 3.61181 20.6691 3.5 20.9997 3.5C21.3302 3.5 21.6073 3.61181 21.8309 3.83542C22.0545 4.05903 22.1663 4.33611 22.1663 4.66667V5.83333H23.333C23.6636 5.83333 23.9406 5.94514 24.1643 6.16875C24.3879 6.39236 24.4997 6.66944 24.4997 7C24.4997 7.33056 24.3879 7.60764 24.1643 7.83125C23.9406 8.05486 23.6636 8.16667 23.333 8.16667H22.1663V9.33333C22.1663 9.66389 22.0545 9.94097 21.8309 10.1646C21.6073 10.3882 21.3302 10.5 20.9997 10.5C20.6691 10.5 20.392 10.3882 20.1684 10.1646C19.9448 9.94097 19.833 9.66389 19.833 9.33333V8.16667Z" fill="var(--colorIconAccentInvariably)"/>
                    </svg>
                  </div>
                </div>
                <div class="empty-collections__body_main">
                  <template v-if="collections.length">
                    <div class="empty-collections-title">{{$t('ТакойПодборкиНеСуществует')}}</div>
                    <div class="empty-collections-description">{{$t('МожетеСоздатьЕё')}}</div>
                  </template>
                  <template v-else-if="collectionsAll.length">
                    <div class="empty-collections-title">{{$t('ДанныеОбъектыУжеДобавленыВоВсеПодборки')}}</div>
                    <div class="empty-collections-description">{{$t('СоздайтеНовуюПодборкуДляНих')}}</div>
                  </template>
                  <template v-else>
                    <div class="empty-collections-title">{{$t('НачнитеСохранятьОбъектыВПодборки')}}</div>
                    <div class="empty-collections-description">{{$t('СоздавайтеПодборкиИДелитесьОбъектамиСКлиентами')}}</div>
                  </template>
                </div>
              </div>
              <div class="empty-collections__separator"></div>
              <div class="empty-collections__footer">
                <default-button type="outline" width="auto" @click-element="startCreateCollection">{{$t('СоздатьПодборку')}}</default-button>
              </div>
            </div>
          </template>
          <template v-else>
            <loader :text="'Загрузка подборок'"></loader>
          </template>
        </div>
      </div>
      <template v-slot:headers-right>
        <div class="collection-create-btn">
          <default-button
              size="medium"
              type="tertiary"
              @click-element="startCreateCollection"
          >{{$t('Создать')}}</default-button>
        </div>
      </template>
      <template v-slot:headers-functions>
        <div class="search-collection">
          <div class="search-collection__container">
            <search-component
              v-model="searchQuery"
            />
          </div>
        </div>
      </template>
    </modal-container>

    <modal-container
        v-if="visibleCreateCollection"
        :title="$t('СозданиеПодборки')"
        :confirmText="$t('Создать')"
        width="560px"
        @close-modal="closeCreateCollection"
        @confirm-option="endCreateCollection"
        :key="'visibleCreateCollection'"
    >
      <form-item
          :title="$t('Название')"
          :required="true"
          :notificationError="errorEmptyName&&!nameCollection"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-new-input :placeholder="$t('ВведитеНазвание')" v-model="nameCollection"  :notificationError="errorEmptyName&&!nameCollection"/>
      </form-item>
    </modal-container>

  </div>
</template>

<script>
import ModalContainer from "@/components/redesign/modalContainer.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import SearchComponent from "@/components/redesign/searchComponent.vue";
import base58 from "bs58";
import {Buffer} from "buffer";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import IndicatorTag from "@/components/redesign/IndicatorTag.vue";
import axios from "axios";
import Loader from "@/components/Loader.vue";

export default {
  name: "addToCollection",
  components: {Loader, IndicatorTag, DefaultNewInput, FormItem, SearchComponent, DefaultButton, ModalContainer},
  data(){
    return{
      searchQuery:'',
      visibleCreateCollection:false,
      visibleAddCollection:true,
      objectsSelected:[],
      errorEmptyName:false,
      nameCollection:'',
      collectionsAll:[], //все подборки без фильтрации
      collections:[], //отфильтрованные подборки
      loadCollections:false
    }
  },
  computed:{
    uuid () {
      return this.$store.getters.getUid
    },
    object () {
      return this.$store.getters.getObject
    },
    addSelectedObjects(){
      return this.$store.getters.getSelectedObjects
    },
    moreInidicator(){
      return this.objectsSelected.length-8+'+'
    },
    idsSelectedObjects(){
      const ids = []
      this.objectsSelected.map(el=>{
        if(el.id){ids.push(el.id)}
      })
      return ids
    },
    idsSelectedCollection(){
      const ids = []
      this.collections.map(el=>{
        if(el.id&&el.selected){ids.push(el.id)}
      })
      return ids
    },
    filterCollections(){
      return this.collections.filter(collection=>collection.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    }
  },
  methods:{
    hasAll(){

    },
    encodeBase(url) {
      return base58.encode(new Buffer(url))
    },
    closeCreateCollection(){
      this.visibleCreateCollection=false
      this.errorEmptyName=false
      this.nameCollection=''
      this.visibleAddCollection=true
    },
    startCreateCollection(){
      this.visibleCreateCollection=true
      this.visibleAddCollection=false
    },
    endCreateCollection(){
      if(this.nameCollection){
        axios.post('/api/send-compl', {
          uuid: this.uuid,
          name: this.nameCollection,
          ids: this.idsSelectedObjects
        })
        this.$store.commit('SET_SELECTED_OBJECTS_ALL', [])
        const resultNotification = `${this.$i18n.t('Подборка')} «${this.nameCollection}» ${this.$i18n.t('создана')}`
        this.nameCollection=''
        this.errorEmptyName=false
        this.visibleCreateCollection=false
        this.$emit('close',resultNotification)
      }else this.errorEmptyName=true
    },
    selectCollection(id){
      this.collections=this.collections.map((el)=>{
        if(el.id===id) el.selected=!el.selected
        return el
      })
    },
    addInCollection(){
      axios.post('/compilations/add', {
        bookmarks:this.idsSelectedCollection,
        objects:this.idsSelectedObjects
      })
      let resultNotification = `${this.$i18n.t('ДобавленоВПодборки')} `
      const namesCollections = []
      this.collections.map(el=>{
        if(el.id&&el.selected){namesCollections.push(`«${el.name}»`)}
      })
      resultNotification = resultNotification + namesCollections.join(', ')
      this.visibleAddCollection=false
      this.$emit('close',resultNotification)
    }
  },
  mounted(){
    this.objectsSelected = this.addSelectedObjects
    if(this.objectsSelected.length === 0 && this.object.id) {
      this.objectsSelected = [{preview: null, id:this.object.id}]
      if(this.object.ImagePlanLayout)this.objectsSelected[0].preview=this.object.ImagePlanLayout
    }
    this.loadCollections=true
    axios.get('/compilations/get-all').then(responseCollection => {
      if(responseCollection.data) {
        this.collectionsAll=responseCollection.data
        this.collections=responseCollection.data.filter(el=>!el.ids.hasAll(this.idsSelectedObjects))
        this.collections.forEach(el=>{el.selected=false})
        this.loadCollections=false
      }
    })
  }
}
</script>

<style scoped lang="scss">
.collection-create-btn{
  padding: 0 4px;
  width: 100%;
}
.search-collection{
  padding: 0 24px;
  .search-collection__container{
    padding: 6px 12px;

  }
}
.body-add-to-collection{
  margin: 12px 0;
  max-height: 372px;
  width: 560px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: var(--colorComponentScrollbarKnob)
  }
  &.fix-height{
    height: 372px;
  }
  .body-add-to-collection__container{
    display: flex;
    flex-direction: column;
    height: 100%;
    .list-objects-selected{
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 2px 24px 6px;
      .list-objects-selected__title{
        color: var(--colorTextSecondary);
        font-variant-numeric: lining-nums tabular-nums;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.035px;
      }
      .list-objects-selected__slider{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        padding-right: 16px;
        overflow: auto;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar{
          display: none;
        }
        .selected-el{
          border-radius: 4px;
          border: 1px solid var(--colorStrokeSecondary);
          width: 96px;
          height: 96px;
          min-width: 96px;
          min-height: 96px;
          padding: 8px;
          .selected-el__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            --linkPlanSelected:'url()';
            background-size: contain;
            background-position: center;
            background-image: var(--linkPlanSelected);
            background-repeat: no-repeat;
          }
        }
        .more-selected-elements{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 96px;
          height: 96px;
          min-width: 96px;
          min-height: 96px;
        }
      }
    }
    .separator-add-collection{
      display: flex;
      height: 16px;
      padding: 0 24px;
      justify-content: center;
      align-items: center;
      .separator-add-collection__line{
        width: 100%;
        height: 1px;
        background: var(--colorStrokeSecondary);
      }
    }
    .list-collection{
      padding: 6px 20px;
      display: flex;
      flex-direction: column;
      .collection-block{
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 16px;
        .collection-block__left{
          padding: 4px 12px 4px 0;
          .collection-block-plan{
            border-radius: 4px;
            border: 1px solid var(--colorStrokeSecondary);
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            padding: 4px;
            .collection-block-plan__container{
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .collection-block-plan__container_img{
                height: 100%;
                width: 100%;
                object-fit: contain;
                margin: 0 auto;
              }
            }
          }
        }
        .collection-block__middle{
          padding: 10px 0;
          width: 100%;
          display: flex;
          align-items: center;
          .collection-block-name{
            color: var(--colorTextPrimary);
            font-variant-numeric: lining-nums tabular-nums;

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            letter-spacing: 0.024px;
          }
        }
        .collection-block__right{
          padding: 6px 0 6px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          .collection-block__right_icon{
            cursor: pointer;
            min-height: 24px;
            max-height: 24px;
            min-width: 24px;
            max-width: 24px;
            svg{
              display: block;
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
    .empty-collections{
      padding: 16px 24px 24px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .empty-collections__body{
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        .empty-collections__body_header{
          .empty-collections-icon{
            min-height: 28px;
            max-height: 28px;
            min-width: 28px;
            max-width: 28px;
            svg{
              display: block;
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
        .empty-collections__body_main{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          .empty-collections-title{
            color: var(--colorTextPrimary);
            text-align: center;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 120% */
            letter-spacing: 0.03px;
          }
          .empty-collections-description{
            color: var(--colorTextSecondary);
            text-align: center;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            letter-spacing: 0.08px;
          }
        }
      }
      .empty-collections__separator{
        height: 24px;
        padding: 0 16px;
      }
      .empty-collections__footer{
        display: flex;
        justify-content: center;
      }
    }
  }
}


@media screen and (max-width: 1919px){
  .search-collection{
    padding: 0 12px;
  }
  .body-add-to-collection{
    max-height: 388px;
    width: 480px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    .body-add-to-collection__container{
      .list-collection{
        padding: 6px 8px;
      }
    }
  }
}
@media screen and (max-width: 1365px){
  .body-add-to-collection{
    max-height: 376px;
  }
}

@media screen and (max-width: 743px){
  .search-collection{
    padding: 0 4px;
  }
  .body-add-to-collection{
    max-height: unset;
    width: 100%;
    .body-add-to-collection__container{
      .list-objects-selected{
        padding: 2px 16px 6px;
        .list-objects-selected__slider{
          .selected-el{
            width: 64px;
            height: 64px;
            min-width: 64px;
            min-height: 64px;
            padding: 8px;
          }
          .more-selected-elements{
            width: 64px;
            height: 64px;
            min-width: 64px;
            min-height: 64px;
          }
        }
      }
      .separator-add-collection{
        padding: 0 16px;
      }
      .list-collection{
        padding: 6px 0;
      }
      .empty-collections{
        padding: 16px 16px 24px;
        .empty-collections__body{
          padding-top: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          .empty-collections__body_header{
            .empty-collections-icon{
              min-height: 28px;
              max-height: 28px;
              min-width: 28px;
              max-width: 28px;
              svg{
                display: block;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
          .empty-collections__body_main{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            .empty-collections-title{
              color: var(--colorTextPrimary);
              text-align: center;
              font-variant-numeric: lining-nums tabular-nums;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 120% */
              letter-spacing: 0.03px;
            }
            .empty-collections-description{
              color: var(--colorTextSecondary);
              text-align: center;
              font-variant-numeric: lining-nums tabular-nums;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 125% */
              letter-spacing: 0.08px;
            }
          }
        }
        .empty-collections__separator{
          height: 24px;
          padding: 0 16px;
        }
        .empty-collections__footer{
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>