<template>
  <div>
    <slot v-if="type==='Country'||type==='Developer'">
      <div class="v-select">
        <div class="select-main" @click="foc">
          <div
              class="title"
          >
            {{selected.value}}
          </div>
          <div class="icon-select" v-if="options.length>1">
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.54307 7.80522C8.24288 8.06493 7.7579 8.06493 7.45693 7.80522L0.325723 1.64162C-0.108574 1.26672 -0.108574 0.656747 0.325723 0.281178C0.76002 -0.0937262 1.46488 -0.0937262 1.89917 0.281178L8.00039 5.5538L14.1 0.281178C14.5351 -0.0937262 15.24 -0.0937262 15.6743 0.281178C16.1086 0.656747 16.1086 1.26672 15.6743 1.64162L8.54307 7.80522Z" fill="#C4C4C4"/>
            </svg>
          </div>
        </div>
        <div class="options" v-if="areOptionsVisible&&options.length>1" v-on-clickaway="closeSelect" :style="{top:'50px'}">
          <div class="overflow">
            <div

                class="options__element"
                v-for="option in options"
                :key="option.value"
                @click="selectOption(option)"
            >
              <div class="role-naming" :style="{width:'100%'}">{{option.value}}</div>
            </div>
          </div>
        </div>
      </div>
    </slot>
    <slot v-else>
      <div class="v-select">
        <div class="select-main" @click="foc">
          <div
              class="title"
          >
            {{selected.role}}
          </div>
          <div class="icon-select" v-if="options.length>1">
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.54307 7.80522C8.24288 8.06493 7.7579 8.06493 7.45693 7.80522L0.325723 1.64162C-0.108574 1.26672 -0.108574 0.656747 0.325723 0.281178C0.76002 -0.0937262 1.46488 -0.0937262 1.89917 0.281178L8.00039 5.5538L14.1 0.281178C14.5351 -0.0937262 15.24 -0.0937262 15.6743 0.281178C16.1086 0.656747 16.1086 1.26672 15.6743 1.64162L8.54307 7.80522Z" fill="#C4C4C4"/>
            </svg>
          </div>
        </div>
        <div class="options" v-if="areOptionsVisible&&options.length>1" v-on-clickaway="closeSelect">
          <div class="options-naming-role">{{$t('Роль')}}:</div>
          <div class="overflow">
            <div

                class="options__element"
                v-for="(option,index) in options"
                :key="option.role+index"
                @click="selectOption(option)"
            >
              <div class="role-naming"><span class="badge bg-primary">{{option.role}}</span> <slot v-if="option.fio">({{option.fio}})</slot></div>
              <div class="role-naming" v-if="option.grouprole || option.org_name"><span class="badge bg-secondary">{{option.grouprole}}</span>
<!--                <slot v-if="option.grouprole===$t('Руководитель')">{{$t('АН')}} {{option.org_name}}</slot>-->
                <slot v-if="option.org_name"> {{$t('АН')}} {{option.org_name}}</slot>
                <slot v-if="option.groupinfo?.name"> «{{option.groupinfo.name}}»</slot>
              </div>
<!--                <slot v-if="option.grouprole!==$t('Руководитель')">«{{option.groupinfo.name}}»</slot>-->
<!--                <slot v-if="option.grouprole!==$t('Руководитель')&&option.org_name">({{$t('АН')}} {{option.org_name}})</slot></div>-->
<!--              <div class="fio-naming">
                {{option.fio}}
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
export default {
  name: "dropDownRole",
  directives: {
    onClickaway: onClickaway,
  },
  props:{
    options:{
      type:Array,
      default(){
        return []
      }
    },
    selected:{
      type:Object,
      default(){
        return {}
      }
    },
    type:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      searchQuery:'',
      areOptionsVisible:false,
      userIds: []
    }
  },
  methods:{
    foc() {
      this.areOptionsVisible=!this.areOptionsVisible
    },
    selectOption(option){
      this.$emit('select-deal',option)
      this.areOptionsVisible=false;
    },
    hideSelect(){
      this.areOptionsVisible=false;
    },
    closeSelect(){
      this.areOptionsVisible=false;
    }
  },
  mounted() {

  },

}
</script>

<style scoped>
.v-select{
  cursor: pointer;
  position: relative;
  min-height: 41px;
  width: 100%;
  background: var(--white-basic);
  border: 2px solid #DBDADA;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}
.select-main{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding-left: 15px;
}
.title{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: var(--gray-500-basic-title);
  padding: 5px;
}

.v-select p{
  margin: 0;
}
.icon-select{
  margin-right: 15px;
  width: 16px;
  height: 8px;
}
.icon-select svg{
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.options{
  text-transform: none;
  background-color: var(--white-basic);
  position: absolute;
  z-index: 1000;
  top:60px;
  right: 0;
  width: 100%;
  box-shadow: 3px 3px 40px rgba(40, 79, 154, 0.2);
  border-radius: 20px;
}
.options-naming-role{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--gray-500-basic-title);
  margin-top: 12px;
  margin-left: 13px;
  margin-bottom: 13px;
}
.overflow{
  max-height: 200px;
  overflow-y: auto;
  overflow-x:hidden;
  padding: 0 5px 12px;
}
.overflow::-webkit-scrollbar {
  width: 10px;
}
.overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #C7C7C7;
  border-radius: 10px;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--blue-900-basic);
}
.options__element{
  padding: 7px 8px;
  color: var(--gray-500-basic-title);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.options__element:hover{
  color: var(--white-basic);
  background: #C7C7C7;
  border-radius: 10px;
}
.role-naming{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
}
.fio-naming{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 67%;
  text-align: right;
}
options__element_active{
  background: #C7C7C7;
  color: var(--white-basic);
}
@media screen and (max-width: 1090px) {
  .v-select{
    min-height: 64px;
    border: 3.14388px solid #DBDADA;
    border-radius: 23.5791px;
  }
  .title{
    font-weight: 500;
    font-size: 22.0072px;
    line-height: 27px;
  }
  .icon-select{
    width: 21px;
    height: 12px;
  }
  .options{
    top:90px;
    box-shadow: 4.71583px 4.71583px 62.8777px rgba(40, 79, 154, 0.2);
    border-radius: 31.4388px;
  }
  .options-naming-role{
    font-size: 22.0072px;
    line-height: 27px;
    margin-top: 19px;
    margin-left: 23px;
    margin-bottom: 20px;
  }
  .overflow{
    max-height: 200px;
    padding: 0 10px 19px;
  }
  .options__element{
    padding: 14px 9px;
  }
  .options__element:hover{
    border-radius: 15px;
  }
  .role-naming{
    font-size: 22.0072px;
    line-height: 27px;
  }
  .fio-naming{
    font-size: 22.0072px;
    line-height: 27px;
  }

}
@media screen and (max-width: 550px){
  .v-select{
    min-height: 41px;
    border: 2px solid #DBDADA;
    border-radius: 15px;
  }
  .title{
    font-size: 14px;
    line-height: 17px;
  }
  .icon-select{
    width: 15px;
    height: 8px;
  }
  .options{
    top:50px;
    box-shadow: 3px 3px 40px rgba(40, 79, 154, 0.2);
    border-radius: 20px;
  }
  .options-naming-role{
    font-size: 14px;
    line-height: 17px;
    margin-top: 12px;
    margin-left: 15px;
    margin-bottom: 13px;
  }
  .overflow{
    max-height: 200px;
    padding: 0 6px 12px;
  }
  .options__element{
    padding: 9px 8px;
  }
  .options__element:hover{
    border-radius: 10px;
  }
  .role-naming{
    font-size: 14px;
    line-height: 17px;
  }
  .fio-naming{
    font-size: 14px;
    line-height: 17px;
  }

}
@media screen and (max-width: 500px) {

  .v-select{
    width: auto;
    height: auto;
  }

}
@media screen and (max-width: 400px) {

}

</style>