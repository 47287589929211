<template>
  <div class="container-block">
    <div v-if="title" class="container-block__title">{{title}} <span class="required-mark" v-if="required">*</span></div>
    <div class="container-block__content">
      <div class="container-block__content-slot">
        <slot></slot>
      </div>
      <svg v-if="removable" @click="$emit('remove-item')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7.275 18L6 16.725L10.725 12L6 7.275L7.275 6L12 10.725L16.725 6L18 7.275L13.275 12L18 16.725L16.725 18L12 13.275L7.275 18Z" fill="#B6B7C3"/>
      </svg>
    </div>

    <div class="container-block__notification" v-if="notificationError&&notificationErrorText">{{notificationErrorText}}</div>
  </div>
</template>

<script>
export default {
  name: "FormItem",
  props:{
    notificationErrorText:{
      type:String,
      default:'',
    },
    notificationError:{
      type:Boolean,
      default:false,
    },
    required:{
      type:Boolean,
      default:false,
    },
    title:{
      type:String,
      default:'',
    },
    removable: {
      type: Boolean,
      default: false
    }
  },
  methods:{},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.container-block{
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px 0;
  width: 100%;
  .container-block__title{
    color: var(--colorTextSecondary);
    font-variant-numeric: lining-nums tabular-nums;

    /* System Font/Label/Label 2XS */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.035px;
    .required-mark{
      color:var(--colorTextNegative);
    }
  }
  .container-block__content {
    display: flex;
    align-items: center;
    gap: 12px;
    .container-block__content-slot {
      width: 100%;
    }
    > svg {
      cursor: pointer;
    }
  }
  .container-block__notification{
    color: var(--colorTextNegative);
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.012px;
  }
}
</style>