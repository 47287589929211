export const NAVIGATION_CATEGORIES = [
    {
        slug: 'profile',
        label: 'Профиль',
        iconSrc: '/my/menu/nav-profile.svg'
    },
    {
        slug: 'clients',
        label: 'Клиенты',
        iconSrc: '/my/menu/nav-clients.svg'
    },
    {
        slug: 'objects',
        label: 'Объекты',
        iconSrc: '/my/menu/nav-objects.svg'
    },
    {
        slug: 'news',
        label: 'Главная',
        iconSrc: '/my/menu/nav-news.svg',
        path: '/'
    },
    {
        slug: 'manage',
        label: 'Управление',
        iconSrc: '/my/menu/nav-manage.svg'
    },
    {
        slug: 'dev',
        label: 'Разработчик',
        iconSrc: '/my/menu/nav-dev.svg'
    },
]