import axios from "axios";

export class YandexRecognizer {
    #_onStart = () => {}
    #_onEnd = () => {}
    #_onResult = () => {}

    constructor({onStart, onEnd, onResult, alwaysListen = false}) {
        this.silenceThreshold = 100
        this.minSilenceTime = 3000
        this.lastTimeSpeaking = Date.now()
        this.recordedChunks = []
        this.mediaRecorder = null
        this.audioContext = new AudioContext()
        this.analyser = this.audioContext.createAnalyser()
        this.source = null
        this.bufferLength = this.analyser.frequencyBinCount
        this.dataArray = new Uint8Array(this.bufferLength)
        this.#_onStart = onStart
        this.#_onEnd = onEnd
        this.#_onResult = onResult
    }

    async start() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
            this.mediaRecorder = new MediaRecorder(stream)

            this.mediaRecorder.addEventListener("dataavailable", (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data)
                }
            });

            this.mediaRecorder.start()

            this.source = this.audioContext.createMediaStreamSource(stream)
            this.source.connect(this.analyser)

            this.analyser.fftSize = 32

            setTimeout(() => {
                this.checkSilence()
            }, 1500)
        } catch (error) {
            console.error("Ошибка при получении доступа к микрофону", error)
        }
    }

    async stop() {
        return new Promise(resolve => {
            if (this.mediaRecorder) {
                this.mediaRecorder.addEventListener('stop', () => {
                    const blob = new Blob(this.recordedChunks, { type: 'audio/mp3' });
                    resolve(blob)
                }, { once: true })

                this.mediaRecorder.stop()
                this.mediaRecorder = null
            }
        });
    }

    async checkSilence() {
        this.analyser.getByteFrequencyData(this.dataArray)

        let sum = 0;
        for(let i = 0; i < this.bufferLength; i++) {
            sum += this.dataArray[i]
        }
        const average = sum / this.bufferLength

        if (average < this.silenceThreshold) {
            if (Date.now() - this.lastTimeSpeaking > this.minSilenceTime) {
                const blob = await this.stop()
                const formData = new FormData()

                formData.append("audio", blob)

                try {
                    const {data} = await axios.post("https://voice.itrielt.ru/recognize", formData)

                    this.#_onResult({
                        isFinal: true,
                        text: data.text
                    })

                    this.recordedChunks = []

                } catch (error) {
                    console.error("Ошибка при отправке данных на сервер", error)
                }
            }
        } else {
            this.lastTimeSpeaking = Date.now()
        }

        if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
            setTimeout(() => this.checkSilence(), 3000)
        }
    }
}