import {isEmpty} from "@/utils";

export class BrowserRecognizer {
    recognition;
    #_alwaysListen = false;
    #_isRecognizing = false;
    #_onEnd = () => {};
    #_onStart = () => {};

    get isRecognizing () {
        return this.#_isRecognizing
    }

    get isAlwaysListen () {
        return this.#_alwaysListen
    }

    /**
     * Создает экземпляр класса BrowserRecognizer.
     *
     * @constructor
     * @param {Object} options - Настройки для инициализации распознавания речи
     * @param {Function} options.onStart - Функция, вызываемая при начале распознавания
     * @param {Function} options.onEnd - Функция, вызываемая при завершении распознавания
     * @param {Function} options.onResult - Функция, вызываемая при получении результатов распознавания
     */
    constructor({onStart, onEnd, onResult, alwaysListen = false}) {
        try {
            const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition //Интерфейс Распознавание голоса
            this.recognition = new Recognition()
        } catch (error) {
            console.error('В этом браузере не поддерживается распознавание речи')
        }

        this.recognition.continuous = true //Проверяет возвращается ли непрерывные результаты или вернулся только один. По умолчанию для одиночного значение (false.)
        this.recognition.interimResults = true //Контроллирует, следует ли возвращать промежуточные результаты (true) или нет (false.) - isFinal

        this.recognition.onresult = (evt) => {
            let isFinal = false

            for (let i=0;i < evt.results.length; i++){
                isFinal = evt.results[i].isFinal //Свойство isFinal интерфейса, доступное только для чтения, SpeechRecognitionResult представляет собой логическое значение, которое указывает, является ли этот результат окончательным ( true) или нет ( false) — если да, то это последний раз, когда этот результат будет возвращен; если нет, то этот результат является промежуточным и может быть обновлен позже.
            }

            const text = Array.from(evt.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('')

            onResult({isFinal, text})
        }

        this.#_alwaysListen = alwaysListen
        this.#_onEnd = onEnd
        this.#_onStart = onStart

        this.recognition.onstart = (e) => {
            this.#_isRecognizing = true
            onStart(e)
        }

        this.recognition.onend = (e) => {
            this.#_isRecognizing = false
            onEnd(e)
        }

        if (alwaysListen) {
            this.start()
        }
    }

    start() {
        if (this.#_alwaysListen && this.isRecognizing) {
            this.#_onStart()
            return
        }


        try {
            this.recognition.start()
        } catch (error) {
            console.error('Ошибка начала записи речи')
        }
    }

    stop() {
        if (this.#_alwaysListen) {
            this.#_onEnd()
            return
        }

        try {
            this.recognition.stop()
        } catch (error) {
            console.error('Ошибка начала записи речи')
        }
    }
}