
export default {
    inserted(el){
        let mouseDown = false
        let startX = 0
        let scrollLeft = 0
        const handleMouseDown = (e)=>{
            mouseDown = true
            startX= e.pageX - el.offsetLeft
            scrollLeft = el.scrollLeft
        }

        const handleMouseLeave = ()=>{
            mouseDown = false
        }
        const handleMouseUp = ()=>{
            mouseDown = false
        }
        const handleMouseMove = (e)=>{
           if(!mouseDown) return;
           e.preventDefault();
           const x = e.pageX - el.offsetLeft;
           const walk = x-startX;
           el.scrollLeft=scrollLeft - walk
        }
        el.addEventListener('mousemove', handleMouseMove)
        el.addEventListener('mouseleave', handleMouseLeave);
        el.addEventListener('mouseup', handleMouseUp);
        el.addEventListener('mousedown', handleMouseDown);
    },
    name:'dragScrollMouse'
}