<template>
  <teleport to="body">
    <transition-group name="list-snackbar" tag="div" class="transition-wrapper">
      <slot></slot>
    </transition-group>
  </teleport>
</template>

<script>
import Teleport from "@/components/redesign/teleport.vue";

export default {
  name: "snackbarListAnimation",
  components: {Teleport},
}
</script>

<style>
.list-snackbar-move, /* применять переход к движущимся элементам */
.list-snackbar-enter-active,
.list-snackbar-leave-active {
  transition: all 0.5s ease;
}

.list-snackbar-enter,
.list-snackbar-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.transition-wrapper{
  width: 100%;
  max-width: 320px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 743px){
  .transition-wrapper{
    max-width: unset;
    bottom: unset;
    flex-direction: column-reverse;
    top: 0;
    right: 0;
    left: 0;
  }
}
</style>