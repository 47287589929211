<template>
  <teleport to="body">
    <div class="default-modal-wrapper" :class="{'default-modal-wrapper-visible':blockScroll, 'mode-split':mode==='split', 'hide-background':!backgroundOverlay}">
      <div class="default-modal" @mousedown.self="closeModal" @touchstart.self="closeModal">
        <div class="default-modal__container" :style="{'--widthModalPage': width, '--heightModalPage': height}"  :class="{'touch-device-full-screen':touchDeviceFullScreen}">
          <default-header
              v-if="headerVisible"
              @on-go-back-button-click="closeModal"
              :title="title"
              :type-icon-back="returnIcon?'return':'close'"
              :number-lines="headerLines"
              :style="{borderRadius: '12px 12px 0 0'}"
              grid="plain"
          >
            <slot name="headers-right"></slot>
            <template v-slot:functions>
              <slot name="headers-functions"></slot>
            </template>
          </default-header>
          <div class="default-modal__container_body" :class="{'custom-modal-body':customBody}">
            <slot></slot>
          </div>
          <div class="default-modal__container_footer" v-if="$slots['footer-left']||btnCloseText||confirmText||confirmLeftIcon||confirmRightIcon">
            <div class="wrapper-content-left">
              <slot name="footer-left"></slot>
            </div>
            <div class="wrapper-btns-right">
              <div class="btn-footer button-cancel" v-if="btnCloseText">
                <default-button
                    type="secondary"
                    :appearance="btnCloseAppearance"
                    @click-element="closeModal"
                >{{$t(btnCloseText)}}</default-button>
              </div>
              <slot name="buttons"></slot>
              <div class="btn-footer" v-if="confirmText||confirmLeftIcon||confirmRightIcon">
                <default-button
                    :disabled="confirmDisabled"
                    :left-icon="confirmLeftIcon"
                    :right-icon="confirmRightIcon"
                    @click-element="confirmModal"
                >{{$t(confirmText)}}</default-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import DefaultTabs from "@/components/redesign/defaultTabs.vue";
import DefaultHeader from "@/components/redesign/defaultHeader.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import Teleport from "@/components/redesign/teleport.vue";

/**
 * Слоты:
 * Слот buttons - вставляет любые кастомные кнопки в футер модалки перед кнопкой подтверждения действий (confirmText)
 * Слот footer-left - вставляет любые кастомные компоненты в левую часть футера. (На мобилке скрывается)
 * Слот headers-right - вставляет любой кастомный функционал в хедер шапки в правую часть на верхней строке.
 * Слот headers-functions - вставляет любой кастомный функционал в хедер шапки до headers-right на верхней строке и на всю строку на нижней строке.
 * Обычный слот - вставляет основной контент в центральную часть модалки.
 *
 * @prop {String} mode - содержит режим модалка: 1.card 2.split
 * @prop {String} title - содержит заголовок модалки
 *
 * @prop {String} confirmText - содержит текст кнопки, которая подтверждает действия в модалке.
 * @prop {Boolean} confirmDisabled - отвечает за блокировку кнопки, которая подтверждает действия в модалке.
 * @prop {String} confirmLeftIcon - содержит левую иконку кнопки, которая подтверждает действия в модалке.
 * @prop {String} confirmRightIcon - содержит правую иконку кнопки, которая подтверждает действия в модалке.
 *
 * @prop {String} btnCloseText - текст дефолтной кнопки закрытия модалки
 * @prop {String} btnCloseAppearance - внешний вид дефолтной кнопка закрытия модалки
 *
 * @prop {Boolean} blockScroll - (!!!ЖЕЛАТЕЛЬНО НЕ ПРИМЕНЯТЬ!!!) Отвечает за блокировку скролла контента внтутри модалки. Иногда нужно, чтобы скроллилась вся модалка, а внутренний контент оставлся без скролла. (!!!ЖЕЛАТЕЛЬНО НЕ ПРИМЕНЯТЬ!!!)
 * Особенно полезно использовать blockScroll, если в контенте есть абсолютно позиционированные элементы, которые должны выходить за рамки модалки, а если будет скролл, то такие элементы обрежутся и не выйдут за пределы.
 *
 * @prop {Boolean} blockAppHidden - Блокировать ли скролл <body>. По дефолту при открытии модалки у body убирается скролл.
 * @prop {Boolean} customBody - Убирает отступы. Есть возможность сделать кастомную контентную часть модалки, убрав дефолтные отступы.
 *
 * @prop {Boolean} returnIcon - По дефолту в шапке слева стоит иконка крестика, но иногда в модалка нужна иконка стрелки назад. returnIcon включает стрелку назад и скрывает крестик.
 * @prop {Number} headerLines - Аналог numberLines из defaultHeader (Отвечает за количество линий. Шапка может быть из 1 или 2 линий. По дефолтку на мобилках 2 строки (0-743px), а на остальных устройствах 1 строка. Вторая строка нужна для "функций").
 *
 * @prop {String} width - Фиксированная ширина модалки
 * @prop {String} height - Фиксированная высота модалки
 *
 * @prop {Boolean} backgroundOverlay - Отвечает за отображение тёмного фона
 * @prop {Boolean} headerVisible - Отвечает за отображение шапки модального окна
 * @prop {Boolean} touchDeviceFullScreen - В mode:card открывает модалку на мобилках на весь экран, независимо от контента внутри и заданной высоты (0-743px). В mode:split открывает модалку на мобилках и планшетах на весь экран (0-1365px)
 */
export default {
  name: "modalContainer",
  components: {Teleport, DefaultButton, DefaultHeader, DefaultTabs},
  props:{
    mode:{
      type:String,
      default:'card'
    },
    title:{
      type:String,
      default:''
    },
    confirmText:{
      type:String,
      default:'Подтвердить'
    },
    confirmDisabled:{
      type:Boolean,
      default:false
    },
    confirmLeftIcon:{
      type:String,
      default:''
    },
    confirmRightIcon:{
      type:String,
      default:''
    },
    btnCloseText:{
      type:String,
      default:'Отменить'
    },
    btnCloseAppearance:{
      type:String,
      default:null
    },
    blockScroll:{
      type:Boolean,
      default:false
    },
    blockAppHidden:{
      type:Boolean,
      default:true
    },
    customBody:{
      type:Boolean,
      default:false
    },
    returnIcon:{
      type:Boolean,
      default:false
    },
    headerLines:{
      type: Number,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    backgroundOverlay:{
      type:Boolean,
      default:true
    },
    headerVisible:{
      type:Boolean,
      default:true
    },
    touchDeviceFullScreen:{
      type:Boolean,
      default:false
    },
  },
  methods:{
    closeModal(){
      this.$emit('close-modal')
    },
    confirmModal(){
      this.$emit('confirm-option')
    },
  },
  mounted() {
    if(this.blockAppHidden||(this.touchDeviceFullScreen&&((this.mode==='split'&&window.innerWidth<=1365)||(this.mode==='card'&&window.innerWidth<=743)))){
      document.body.style.setProperty("overflow", "hidden");
    }
  },
  beforeDestroy() {
    if(this.blockAppHidden||(this.touchDeviceFullScreen&&((this.mode==='split'&&window.innerWidth<=1365)||(this.mode==='card'&&window.innerWidth<=743)))) {
      document.body.style.setProperty("overflow", "");
    }
  }
}
</script>

<style lang="scss" scoped>
.default-modal-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: var(--colorOverlay);

  animation-name:show-back;
  animation-duration: 0.5s;
  &.hide-background{
    background: transparent;
  }
  .default-modal{
    padding: 16px;
    height: 100%;
    width: 100%;
    display: flex;
    overflow: auto;

    animation-name:show-modal ;
    animation-duration: 0.5s;
    .default-modal__container{
      --widthModalPage: auto;
      --heightModalPage: auto;
      border-radius: 12px;
      background: var(--colorBackgroundContent);
      display: flex;
      flex-direction: column;
      margin: auto;
      max-height: 100%;
      min-height: 200px;
      height: var(--heightModalPage);
      width: var(--widthModalPage);
      .default-modal__container_body{
        flex-grow: 1;
        padding: 0 36px;
        margin: 12px 0;
        overflow: auto;
        &.custom-modal-body{
          padding: 0;
          margin: 0;
        }
        &::-webkit-scrollbar {
          width: 12px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background: var(--colorComponentScrollbarKnob)
        }
      }
      .default-modal__container_footer{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--colorStrokePrimary);
        padding: 20px 36px;
        gap: 16px;
        .wrapper-btns-right{
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: flex-end;
        }
      }
    }
  }
  &.default-modal-wrapper-visible{
    .default-modal{
      .default-modal__container{
        max-height: unset;
        .default-modal__container_body{
          overflow: visible;
        }
      }
    }
  }
  &.mode-split{
    z-index: 2500;
    &.hide-background{
      left: unset;
    }
    .default-modal{
      padding: 0;
      .default-modal__container{
        height: 100%;
        border-radius: 0;
        margin: 0 0 0 auto;
      }
    }
  }
}

@keyframes show-modal {
  0%{
    transform: translate3d(0px, 100%, 0px);
  }
  100%{
    transform: translate3d(0px, 0, 0px);
  }
}
@keyframes show-back {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@media screen and (max-width: 1919px){
  .default-modal-wrapper{
    .default-modal{
      .default-modal__container{
        .default-modal__container_body{
          padding: 0 24px;
          &::-webkit-scrollbar {
            width: 6px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1365px){
  .default-modal-wrapper{
    .default-modal{
      padding: 0;
      .default-modal__container{
        .default-modal__container_body{
          padding: 0 24px;
        }
        .default-modal__container_footer{
          padding: 12px 24px;
        }
      }
    }
    &.mode-split{
      z-index: 3500;
      &.hide-background{
        left: 0;
      }
      .default-modal{
        .default-modal__container{
          height: auto;
          width: 100%;
          margin: auto auto 0;
          border-radius: 12px 12px 0 0;
          &.touch-device-full-screen{
            height: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 743px){
  .default-modal-wrapper{
    .default-modal{
      .default-modal__container{
        border-radius: 12px 12px 0 0;
        margin: auto auto 0;
        width: 100%;
        &.touch-device-full-screen{
          height: 100%;
        }
        .default-modal__container_header{
          padding: 0 4px;
        }
        .default-modal__container_body{
          padding: 0 16px;
          margin: 8px 0 0 0;
        }
        .default-modal__container_footer{
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items:stretch;
          border-top: 0;
          padding: 12px 16px;
          .wrapper-content-left{
            display: none;
          }
          .wrapper-btns-right{
            flex-direction: column-reverse;
          }
          .btn-footer{
            width: 100%;
            &.button-cancel{
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>