export class OpenPageCommand {
    #_subject = '';

    get mappedPath() {
        const mapping = {
            'ШАХМАТКА': 'board',
            'КОММЕРЦИЯ': 'commercial',
            'ПРОФИЛЬ': 'profile',
            'СДЕЛКА': 'deals',
            'СТАТИСТИКА': 'stats',
            'ПОДБОР': '',
            'КВАРТИРА': ''
        }

        return mapping[this.#_subject]
    }

    constructor(context) {
        this.#_subject = context.subject
    }

    execute(context) {
        const self = context.object
        self.$router.push('/' + this.mappedPath)
    }
}