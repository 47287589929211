<template>
  <div class="indicator-badge" :class="[appearance]">

  </div>
</template>

<script>
/**
 * @prop {String} appearance - Отвечает за внешний вид значка и имеет следующие состояния:
 * 1.default-appearance (дефолтное состояние)
 * 2.negative
 * 3.neutral
 */
export default {
  name: "IndicatorBadge",
  props:{
    appearance: {
      type: String,
      default: 'default-appearance'
    }
  }
}
</script>

<style scoped lang="scss">
.indicator-badge{
  width: 6px;
  height: 6px;

  border-radius: 6px;
  background: var(--colorIconAccentInvariably);

  &.negative{
    background: var(--colorIconNegative);
  }

  &.neutral{
    background: var(--colorIconPrimary);
  }
}
</style>